<template>
    <v-app id="inspire" class="v-application--landing">
        <sidebar-landing :visible.sync="drawer" color="purple darken-1" items-preset="landing">
            <template #logo.after>
                <div class="pt-10 px-6 pb-4">
                    <v-btn 
                        :elevation="0" 
                        to="/login" 
                        color="green darken-0"
                        class="w-100"
                    >
                        Авторизация
                    </v-btn>
                </div>
            </template>
        </sidebar-landing>
        <v-main class="white">
            <router-view
                :key="$route.path" 
            />
        </v-main>
    </v-app>
</template>
<script>
import SidebarLanding from '@/components/template/SidebarLanding.vue'
export default {
    components: {
        SidebarLanding
    },
    data() {
        return {
            ready: true,
            drawer: true
        }
    },
    computed: {
    	breadcrumbs() {
    		return _.map(_.get(this.$route, 'meta.breadcrumbs', []), (item) => {
                const id = _.get(this, '$route.params.id', 0);
                _.set(item, 'href', _.replace(item.href, ':id', id));
                return item;
            });
    	}
    },
    beforeMount() {
        return this.$store.getters['user/isAuthorized'] === true ? this.$router.push('/home').catch(() => {}) : null;
    }
};
</script>