<template>
    <v-navigation-drawer 
        v-model="drawer" 
        permanent
        app 
        dark 
        :color="color"
    >
        <date-time />

        <logo :width="97" preset="sidebar" />

        <slot name="logo.after" />

        <v-list dense :class="['v-list--' + itemsPreset]">
            <template v-for="item in myItems">
                <v-divider v-if="item.border_top" :key="item.heading" />
                <v-row v-if="item.heading" :key="item.heading" align="center">
                    <v-col cols="6">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                </v-row>
                <v-row v-else-if="item.divider" :key="item.divider" justify="center" align="center">
                    <v-col cols="11">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-list-group v-else-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-html="item.text" />
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="(child, i) in item.children" :key="i" link>
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title v-html="child.text" />
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else-if="_.has(item, 'handler')" :key="item.text" @click.prevent.stop="item.handler">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-html="item.text" />
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else :key="item.text" link :to="item.href">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-html="item.text" />
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import DateTime from './DateTime.vue'
import Logo from './Logo.vue'
export default {
    name: 'Sidebar',
    components: { DateTime, Logo },
    props: {
        color: {
            type: String,
            default: 'purple darken-2'
        },
        visible: {
            type: Boolean,
            default: false
        },
        itemsPreset: {
            type: String,
            default: 'default'
        }
    },
    data() {
        return {
            items: {
                landing: [
                    { icon: 'mdi-home', text: 'О проекте', href: '/' },
                    { icon: 'mdi-inbox', text: 'Методические<br />материалы', href: '/materials' },
                ],
                default: [
                    { heading: 'Состояние'},
                    { icon: 'mdi-shield-account', text: 'Лицензии', href: '/license' },
                    { heading: 'Управление'},
                    { icon: 'mdi-card-account-details-outline', text: 'Группы', href: '/group' },
                    { icon: 'mdi-account-arrow-left-outline', text: 'Пользователи', href: '/user' },
                    { icon: 'mdi-logout-variant', text: 'Выйти', handler: async () => {
                        await this.$store.dispatch('user/logout');
                        return this.$router.go('/').catch(() => {});
                    }}
                ]
            }
        }
    },
    computed: {
        myItems() {
            return this.items[this.itemsPreset];
        },
        drawer: {
            get: function(){
                return this.visible;
            },
            set: function(newValue){
                this.$emit('update:visible', newValue)
            }   
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch('user/logout');
            try
            {
                return this.$router.go('/');
            }
            catch(err) {
                console.log(err);
            }

            return ;
        }
    }
}
</script>
<style lang="scss">
.v-list--landing{
    .v-list-item--active{
        background-color: #0D0E17;
        &:before{
            opacity: 0!important;
        }
    }
}
</style>