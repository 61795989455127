<template>
    <div class="datetime-component grey--text darken-4 pl-7 pt-7 pb-5">
        <div>{{ humanDate }}</div>
        <div>{{ time }}</div>
    </div>
</template>
<script>
export default {
    name: 'DateTime',
    data() {
        return {
            interval: null,
            date: new Date()
        }
    },
    computed: {
        humanDate() {
            return _.join([
                this.leadZero(_.parseInt(this.date.getDate())),
                _.get(
                    ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'], 
                    _.parseInt(this.date.getMonth()), 
                    ''
                )
            ], ' ');
        },
        time() {
            return _.join([
                this.leadZero(this.date.getHours()), 
                this.leadZero(this.date.getMinutes())
            ], ':');
        }
    },
    methods: {
        leadZero(value) {
            return _.parseInt(value) < 10 ? '0' + value.toString() : value.toString();
        }
    },
    created() {
        this.interval = setInterval(() => {
            this.date = new Date();
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    }
}
</script>
<style lang="scss" scoped>
    .datetime-component{
        opacity: 0.5;
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 1.25rem;
    }
</style>