<template>
    <div class="logo text-center" :class="['logo--' + preset]">
        <div class="d-flex flex-row flex-nowrap justify-center align-center">
            <router-link to="/">
                <v-img 
                    src="@/assets/img/logo.png" 
                    class="flex-grow-0"
                    :width="width"
                />
            </router-link>
        </div>

        <p class="my-4" :class="[activePreset.color]">СдамВПР</p>
    </div>
</template>
<script>
export default {
    name: 'Logo',
    props: {
        width: Number,
        preset: String
    },
    data() {
        return {
            presets: {
                login: {
                    color: 'grey--text darken-2'
                },
                sidebar: {
                    color: 'grey--text darken-4'  
                }
            }
        }
    },
    computed: {
        activePreset() {
            return _.get(this, `presets.${this.preset}`, null);
        }
    }
}
</script>
<style lang="scss" scoped>
    .logo{
        &.logo--sidebar{
            padding: 0 2rem 0 2rem;
            font-size: 0.9rem;
        }
    }
</style>